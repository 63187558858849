import {useState} from "react";
import {useZxing} from "react-zxing";

export const BarcodeScanner = (props) => {
  const [result, setResult] = useState(null);
  const {ref} = useZxing({
    onResult(result) {
      props.handleScannedCode(result.getText());
      setResult(result.getText());
    },
  });

  return (
    <>
      <div id={'readerVideo'}>
        <video ref={ref}/>
      </div>
      <p>
        <span>Last result:</span>
        <span>{result}</span>
      </p>
    </>
  );
};