import {BarcodeScanner} from "../BarcodeScanner";
import {useState} from "react";

export function AddGames(props) {
  const [scannedCode, setScannedCode] = useState(undefined)

  let handleScannedCode = code => {
    console.log(code);
    setScannedCode(code);
  }

  return <>
    <h1>MEHR SPIELE!!</h1>
    <BarcodeScanner handleScannedCode={handleScannedCode}/>
    Ich habe hier ein {scannedCode}
    <button onClick={() => props.setActiveSubPage(undefined)} className={'secondary'}>Abbrechen</button>
  </>;
}