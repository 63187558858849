import {useState} from "react";
import {AddGames} from "./AddGames";

export function Games(props) {
  let [activeSubPage, setActiveSubPage] = useState(undefined);

  if (activeSubPage === 'addGames') {
    return <AddGames setActiveSubPage={setActiveSubPage}/>;
  } else if (activeSubPage === undefined) {
    return <>
      <h1>Spielesammlung</h1>
      <button onClick={() => setActiveSubPage('addGames')}>
        Spiele hinzufügen
      </button>
    </>;
  }
}