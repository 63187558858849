import './App.css';
import {useState} from "react";
import {Rounds} from "./Rounds";
import {Games} from "./games/Games";
import {Players} from "./Players";

function App() {
  const [activeTab, setActiveTab] = useState('rounds');

  return (
    <>
      <main className={'container'} style={{marginBottom: '3rem'}}>
        {activeTab === 'rounds' && <Rounds />}
        {activeTab === 'games' && <Games/>}
        {activeTab === 'players' && <Players/>}
      </main>
      <footer style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: 'var(--secondary)'
      }}>
        <nav>
          <a href={'#'} onClick={() => setActiveTab('rounds')}>Runden</a>
          <a href={'#'} onClick={() => setActiveTab('games')}>Spielesammlung</a>
          <a href={'#'} onClick={() => setActiveTab('players')}>Leute</a>
        </nav>
      </footer>
    </>
  );
}

export default App;
